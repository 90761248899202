import { MenuItem } from '@mui/material';
import { ReactNode } from 'react';

interface DropDownMenuItemProps {
  id: string;
  children: ReactNode;
  onClick?: () => void;
}
export function DropDownMenuItem({ id, onClick, children }: DropDownMenuItemProps) {
  return (
    <MenuItem id={id} onClick={onClick}>
      {children}
    </MenuItem>
  );
}
