import { useIntl } from 'react-intl';

import { useDeleteShop } from '../../../api/hooks/useShopApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { CancelButton } from '../../library/buttons/CancelButton';
import { ConfirmButton } from '../../library/buttons/ConfirmButton';
import { Dialog } from '../../library/dialog/Dialog';

export function DeleteShopEntityDialog() {
  const { formatMessage } = useIntl();
  const { setOpenDeleteDialog, openDeleteDialog, entitieIdsToDelete } = useCrudPageUiStateContext();
  const deleteShop = useDeleteShop();

  const isShopEntity = () => entitieIdsToDelete?.[1] === 'shop' ?? false;

  const handleCancel = () => setOpenDeleteDialog(false);

  const handleConfirm = () => {
    if (entitieIdsToDelete) {
      deleteShop.mutate({ shopId: entitieIdsToDelete[0] });
      handleCancel();
    }
  };

  return (
    <>
      {openDeleteDialog && isShopEntity() && (
        <Dialog
          id="DeleteShopEntityDialog"
          isOpen={openDeleteDialog}
          title={formatMessage({ id: 'shop.delete' })}
          content={formatMessage({ id: 'shop.delete.confirmation' })}
          buttons={
            <>
              <CancelButton onClick={handleCancel} />
              <ConfirmButton onClick={handleConfirm} />
            </>
          }
        />
      )}
    </>
  );
}
