import { FormControl, InputLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { DropDown, DropDownItem } from '../inputs/DropDown';

export interface FormDropdownProps {
  id: string;
  label: string;
  name: string;
  dropDownItems: DropDownItem[];
  required?: boolean;
  fullWidth?: boolean;
}
export function FormDropDown({
  id,
  label,
  name,
  dropDownItems,
  required,
  fullWidth = true,
}: FormDropdownProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ my: 1 }} fullWidth={fullWidth} required={required}>
          <InputLabel sx={{ bgcolor: 'white', px: 1 }}>{label}</InputLabel>
          <DropDown id={id} value={value} dropDownItems={dropDownItems} onChange={onChange} />
        </FormControl>
      )}
    />
  );
}
