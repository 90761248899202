import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { QuantityRestrictionParameter } from '../../types/quantity-restrictions/CreateQuantityRestrictionParameter';
import { QuantityRestriction } from '../../types/quantity-restrictions/QuantityRestriction';

export const QUANTITY_RESTRICTIONS_QUERY_KEY = 'quantity-restrictions';

export function useGetQuantityRestrictions() {
  const client = useClient<QuantityRestriction[]>();
  const result = useQuery(QUANTITY_RESTRICTIONS_QUERY_KEY, () => client('/quantity-restrictions'));
  return { ...result, quantityRestrictions: result.data?.data ?? [] };
}

export function useCreateQuantityRestriction() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<QuantityRestrictionParameter>) =>
      client('/quantity-restrictions', {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUANTITY_RESTRICTIONS_QUERY_KEY);
      },
    }
  );
}
export function usePutQuantityRestriction(quantityRestrictionId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<QuantityRestrictionParameter>) =>
      client(`/quantity-restrictions/${quantityRestrictionId}`, {
        method: 'PUT',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUANTITY_RESTRICTIONS_QUERY_KEY);
      },
    }
  );
}
