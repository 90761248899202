import { useOktaAuth } from '@okta/okta-react';
import { ReactNode } from 'react';
interface AuthCheckerProps {
  children: ReactNode;
}
export function AuthChecker({ children }: AuthCheckerProps) {
  const { oktaAuth, authState } = useOktaAuth();
  if (!authState) {
    oktaAuth.handleLoginRedirect();
    return <></>;
  }

  if (!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect();
    return <></>;
  }
  return <>{children}</>;
}
