import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useSystems } from '../../api/hooks/useSystemsApi';
import { useRRSystemContext } from '../../context/rrSystemContext';
import { System, SystemStatus } from '../../types/systems/System';
import { SystemRowFieldName, SystemRow } from '../../types/systems/SystemRow';
import { entityEditButtonFactoryMethod } from '../common/crud/EditEntityButton';
import Icon from '../library/icons/Icon';
import { ColumnType, DataTable, DataTableColumn } from '../library/table/DataTable';

export function SystemsTable() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rrSystem, selectRRSystem, exitRRSystem } = useRRSystemContext();
  const { isLoading, systems } = useSystems();
  useEffect(() => {
    if (rrSystem) exitRRSystem();
  }, []);

  return (
    <DataTable
      rows={createRows()}
      columns={createColumns()}
      loading={isLoading}
      actionButtonsFactoryMethod={actionButtonsFactoryMethod}
      onRowClick={id => {
        handleRowClick(id);
      }}
    />
  );

  function actionButtonsFactoryMethod(id: string) {
    const system = systems.find(p => p._id == id);
    return <>{entityEditButtonFactoryMethod(system?._id ?? "", system)}</>;
  }

  function createRows() {
    return systems.map((system: System): SystemRow => {
      return {
        id: system.id,
        country: system.country,
        city: system.city,
        client: system.client,
        site: system.site,
        adminEmails: system.adminEmails,
        status: system.status,
        notificationLanguage: system.notificationLanguage,
        gdcTenantId: system.gdcTenantId,
      };
    });
  }

  function handleRowClick(id: string) {
    const system = systems.find(p => p._id === id);
    selectRRSystem(system);
    navigate('/dashboard');
  }

  function createColumns() {
    const columns: DataTableColumn[] = [
      {
        field: SystemRowFieldName.country,
        headerName: intl.formatMessage({ id: 'systems.country' }),
      },
      {
        field: SystemRowFieldName.city,
        headerName: intl.formatMessage({ id: 'systems.city' }),
      },
      {
        field: SystemRowFieldName.client,
        headerName: intl.formatMessage({ id: 'systems.client' }),
      },
      {
        field: SystemRowFieldName.site,
        headerName: intl.formatMessage({ id: 'systems.site' }),
      },
      {
        field: SystemRowFieldName.adminEmails,
        headerName: intl.formatMessage({ id: 'systems.adminMail' }),
        type: ColumnType.List,
      },
      {
        field: SystemRowFieldName.status,
        headerName: intl.formatMessage({ id: 'systems.status' }),
        renderCell: param => {
          return <SystemStatusIcon status={param.value} />;
        },
      },
    ];
    return columns;
  }
}

interface SystemStatusIconProps {
  status: string;
}

function SystemStatusIcon({ status }: SystemStatusIconProps) {
  if (status === SystemStatus.LIVE)
    return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
  if (status === SystemStatus.NOT_LIVE) return <Icon iconName={'blocked'} />;
  else return <Icon iconName={'minus'} />;
}
