import { useIntl } from 'react-intl';

import { useGenerateApiKey } from '../../api/hooks/useGenerateApiKey';
import { Button } from '../../components/library/buttons/Button';
import { CancelButton } from '../../components/library/buttons/CancelButton';
import { FullWidthButton } from '../../components/library/buttons/FullWidthButton';
import { Paper } from '../../components/library/containers/Paper';
import { Dialog } from '../../components/library/dialog/Dialog';
import { odxWarningColor } from '../../context/themeContext';

import { DialogStateProvider, useDialogStateContext } from './DialogStateProvider';

export function GenerateApiKeyButton() {
  return (
    <DialogStateProvider>
      <OpenApiKeyDialogButton />
      <GenerateApiKeyDialog />
    </DialogStateProvider>
  );
}
function OpenApiKeyDialogButton() {
  const intl = useIntl();
  const { setOpenDialog } = useDialogStateContext();
  return (
    <FullWidthButton
      onClick={() => {
        setOpenDialog(true);
      }}
    >
      {intl.formatMessage({ id: 'api-docs.generateKey' })}
    </FullWidthButton>
  );
}
function GenerateApiKeyDialog() {
  const intl = useIntl();
  const { openDialog } = useDialogStateContext();
  return (
    <>
      {openDialog && (
        <Dialog
          id={'GenerateApiKeyDialog'}
          title={intl.formatMessage({ id: 'api-docs.generateKey' })}
          isOpen={openDialog}
          content={<GenerateApiKeyDialogContent />}
          buttons={<CloseDialogButton />}
          width={'md'}
        />
      )}
    </>
  );
}
function GenerateApiKeyDialogContent() {
  const intl = useIntl();
  const { mutate: generateApiKey, data: apiKey } = useGenerateApiKey();

  return (
    <>
      <Paper style={{ backgroundColor: odxWarningColor, padding: '5px', marginBottom: '5px' }}>
        <b>{intl.formatMessage({ id: 'api-docs.generateKeyWarningLabel' })}</b>
        {intl.formatMessage({ id: 'api-docs.generateKeyWarningSentence' })}
      </Paper>
      <div style={{ display: 'flex' }}>
        {!apiKey?.data && (
          <Button
            variant="contained"
            onClick={() => {
              generateApiKey();
            }}
          >
            {intl.formatMessage({ id: 'api-docs.generateKeyShort' })}
          </Button>
        )}
        {apiKey?.data && (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(apiKey.data as string);
              }}
            >
              {intl.formatMessage({ id: 'api-docs.copyKey' })}
            </Button>
            <Paper style={{ width: '100%', padding: '5px', marginLeft: '5px' }}>
              {apiKey.data as string}
            </Paper>
          </>
        )}
      </div>
      {apiKey?.data && (
        <p style={{ padding: '10px' }}>
          {intl.formatMessage({ id: 'api-docs.apiKeyExplanation' })}
        </p>
      )}
    </>
  );
}
function CloseDialogButton() {
  const { setOpenDialog } = useDialogStateContext();
  return (
    <CancelButton
      onClick={() => {
        setOpenDialog(false);
      }}
    />
  );
}
