import { useCreateShop } from '../../../api/hooks/useShopApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { ShopFormValues } from '../../../types/shops/ShopFormValues';
import { ShopParameter } from '../../../types/shops/ShopParameter';
import { ApiError } from '../../../types/types';
import { CreateEntityFormButtons } from '../../common/crud/CreateEntityFormButtons';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { ShopForm } from '../form/ShopForm';

export function CreateShopForm() {
  const { setNotificationMessage } = useNotificationMessageContext();
  const { setOpenCreateDialog } = useCrudPageUiStateContext();
  const { mutateAsync: createShop, reset: resetCreateShop, isLoading } = useCreateShop();

  function handleSubmit(shopParam: ShopParameter) {
    createShop(shopParam, {
      onSuccess: () => {
        resetCreateShop();
        setNotificationMessage('shop.saved', NotificationType.Success);
        setOpenCreateDialog(false);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`shops.${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('shops.generic-create-error', NotificationType.Error);
          }
        }
      },
    });
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <ShopForm
        handleSubmit={handleSubmit}
        buttons={<CreateEntityFormButtons />}
        initialFormValues={initialCreateShopFormValues()}
      />
    </>
  );
}

function initialCreateShopFormValues(): ShopFormValues {
  return {
    name: '',
  };
}
