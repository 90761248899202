import { IntlShape } from "react-intl";

import { ApiError, EntriesErrorCodes, ErrorDetails, FormatErrorCodes, GeneralErrorCodes, HeadersErrorCodes } from "../../../types/types";

export function renderUploadError(uploadError: any, intl : IntlShape): string[] {
    if(!uploadError || !uploadError.response)
    {
      return [intl.formatMessage({ id: 'renters.uploadDataFailed.error.generic' })];
    } 
    if (validateErrorStructure(uploadError.response.data)) {
      return handleUploadError(intl, uploadError.response?.data);
    }
    return [intl.formatMessage({ id: 'renters.uploadDataFailed.error.generic' })];
  }
  function validateErrorStructure(apiResponse: any): apiResponse is ApiError {
    return (
      apiResponse !== null &&
      typeof apiResponse === 'object' &&
      'status' in apiResponse &&
      'message' in apiResponse &&
      'code' in apiResponse &&
      typeof apiResponse.status === 'number' &&
      typeof apiResponse.message === 'string' &&
      typeof apiResponse.code === 'string' &&
      validateErrorDetails(apiResponse.details)
    );
  }
  function validateErrorDetails(details: any): details is ErrorDetails[] {
    if (!details || !details.length) return true;

    return (
      details !== null &&
      typeof details === 'object' &&
      details.every((detail: any) => {
        return (
          'code' in detail &&
          'message' in detail &&
          typeof detail.code === 'string' &&
          typeof detail.message === 'string' &&
          validateErrorParams(detail.params)
        );
      })
    );
  }
  function validateErrorParams(params: any): params is string[] {
    if (!params || !params.length) return true;

    return params.every((param: any) => typeof param === 'string');
  }
  function handleUploadError(intl: IntlShape, rentersUploadError: ApiError) : string[] {
    
    if (rentersUploadError.code in GeneralErrorCodes) { 
      return [
        intl.formatMessage({
          id: `renters.uploadDataFailed.${rentersUploadError.code}`,
        }),
      ];
    }
    if (rentersUploadError.details?.length) {
      return rentersUploadError.details.map((detail: ErrorDetails) => {
        if (!detail.parameters) {
          return intl.formatMessage(
            {
              id: `renters.uploadDataFailed.${detail.code}`,
            }) as string;
        }
        if (detail.code in EntriesErrorCodes || detail.code in HeadersErrorCodes) {
          return intl.formatMessage(
            {
              id: `renters.uploadDataFailed.${detail.code}`,
            },
            {
              line: detail.parameters[0],
              parameter: joinArrayEntries(detail.parameters),
            }
          ) as string;
        }
        if (detail.code in FormatErrorCodes) {
          return intl.formatMessage(
            {
              id: `renters.uploadDataFailed.${detail.code}`,
            },
            {
              parameter: detail.parameters[0],
            }
          ) as string;
        }
        return intl.formatMessage(
            {
              id: `renters.uploadDataFailed.unknownDetailError`,
            })
      });
    }
    return []
  }
  function joinArrayEntries(originalArray: string[]) : string{
    return originalArray.length > 1 ? originalArray.slice(1).join(', ') : "";
  }