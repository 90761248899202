import { useIntl } from 'react-intl';

import { useItemTypeImage } from '../../../api/hooks/useRentingApi';
import Icon from '../../../components/library/icons/Icon';
import { DetailedItemType } from '../../../types/item-types/ItemType';
import { defaultItemTypeImage } from '../../../utils/encodedImages';

interface ItemTypeDetailCardProps {
  itemType: DetailedItemType | null;
}

function ItemTypeDetailCard({ itemType }: ItemTypeDetailCardProps) {
  const intl = useIntl();
  const {imageUrl} = useItemTypeImage(itemType?._id);
  if (!itemType) return <></>;

  const xDockInfo = () => {
    const isXDockItem = itemType.xDockTestable;
    const isGdcCalibration = itemType.gdcCalibration;

    const getIcon = (isCheck: boolean) => {
      if (isCheck) {
        return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
      }
      return <Icon iconName={'blocked'} />;
    };

    return (
      <>
        <div className="x-dock-label">
          {getIcon(isXDockItem)}
          <h3>{intl.formatMessage({ id: 'item-types.xDockTestable' })}</h3>
        </div>
        {isXDockItem && (
          <>
            <div className="card-infos">
              <p>
                <span className="label">
                  {intl.formatMessage({ id: 'item-types.xDockAlertingTime' })}:{' '}
                </span>
                {itemType.xDockAlertingTime ?? '10'}{' '}
                {intl.formatMessage({ id: 'general.minutes.abbreviation' })}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'item-types.xDockTestExpirationTime',
                  })}
                  :{' '}
                </span>
                {itemType.xDockTestExpirationTime ?? '7'}{' '}
                {intl.formatMessage({ id: 'general.days' })}
              </p>
            </div>
          </>
        )}
        <div className="x-dock-label">
          {getIcon(isGdcCalibration)}
          <h3>{intl.formatMessage({ id: 'item-types.gdcCalibration' })}</h3>
        </div>
        {isGdcCalibration && (
          <>
            <div className="card-infos">
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'item-types.gdcCalibrationInterval',
                  })}
                  :{' '}
                </span>
                {itemType.gdcCalibrationInterval} {intl.formatMessage({ id: 'general.days' })}
              </p>
              <p>
                <span className="label">
                  {intl.formatMessage({
                    id: 'item-types.gdcCalibrationBlockPeriod',
                  })}
                  :{' '}
                </span>
                {itemType.gdcCalibrationBlockPeriod ?? '0'}{' '}
                {intl.formatMessage({ id: 'general.days' })}
              </p>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="info-card">
      <div className="card-content">
        <div className="img-container">
          <img src={imageUrl ?? defaultItemTypeImage} alt={itemType?.name ?? 'Default Image'} />
        </div>
        <h4>{itemType?.name}</h4>
        <div>{xDockInfo()}</div>
      </div>
    </div>
  );
}

export { ItemTypeDetailCard };
