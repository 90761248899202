import { useMutation } from 'react-query';

import { useClient } from '../../context/auth/UseClient';

export function useGenerateApiKey() {
  const client = useClient();
  return useMutation(
    () =>
      client("/subscription-key", {
        method: 'GET'
      })
  );
}


