import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthChecker } from '../components/app/AuthChecker';
import { useConfigContext } from '../shared/configContext';

import { AppProvider } from './appContext';
import { CurrentUserProvider } from './auth/CurrentUserContext';
import { LanguageProvider } from './languageContext';
import { RRSystemProvider } from './rrSystemContext';
import { ThemeProvider } from './themeContext';
import { TimeZoneProvider } from './timeZoneContext';

interface AppProvidersProps {
  children: ReactNode;
}

function AppProviders({ children }: AppProvidersProps) {
  const navigate = useNavigate();
  const { config } = useConfigContext();
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const oktaAuth = useMemo(() => {
    return new OktaAuth({
      issuer: config.OKTA_LOGIN_URL,
      clientId: config.OKTA_BACKOFFICE_CLIENT_ID,
      redirectUri: config.OKTA_BACKOFFICE_REDIRECT_URI,
      postLogoutRedirectUri: config.OKTA_BACKOFFICE_REDIRECT_URI,
    });
  }, [config]);
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthChecker>
        <LanguageProvider>
          <ThemeProvider>
            <TimeZoneProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CurrentUserProvider>
                  <RRSystemProvider>
                    <AppProvider>{children}</AppProvider>
                  </RRSystemProvider>
                </CurrentUserProvider>
              </LocalizationProvider>
            </TimeZoneProvider>
          </ThemeProvider>
        </LanguageProvider>
      </AuthChecker>
    </Security>
  );
}

export { AppProviders };
