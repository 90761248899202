export enum AlertRuleType {
  ReturnOverdueWarning = 'ReturnOverdueWarning',
  ReturnOverdueLost = 'ReturnOverdueLost',
  StationUnhealthy = 'StationUnhealthy',
  RentersPending = 'RentersPending',
  QualificationsExpiry = 'QualificationsExpiry',
  QualificationWarningPerRenter = 'QualificationWarningPerRenter',
  ItemTypeAvailabilityWarning = 'ItemTypeAvailabilityWarning',
  ItemTypeServiceWarning = 'ItemTypeServiceWarning'
}
