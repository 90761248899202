import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { AlertRule } from '../../types/alert-rules/AlertRule';
import { AlertRuleParameter } from '../../types/alert-rules/AlertRuleParameter';
import { AlertRuleTemplate } from '../../types/alert-rules/AlertRuleTemplate';

export const ALERT_RULES_QUERY_KEY = 'alert-rules';

export function useGetAlertRules() {
  const client = useClient<AlertRule[]>();
  const result = useQuery(ALERT_RULES_QUERY_KEY, () => client('/alert-rules'));
  return { ...result, alertRules: result.data?.data ?? [] };
}

export function useGetAlertRuleTemplates() {
  const client = useClient<AlertRuleTemplate[]>();
  const result = useQuery('alert-rule-templates', () => client('/alert-rule-templates'));
  return { ...result, alertRuleTemplates: result.data?.data ?? [] };
}

export function useCreateAlertRule() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<AlertRuleParameter>) =>
      client('/alert-rule', {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ALERT_RULES_QUERY_KEY);
      },
    }
  );
}
export function usePutAlertRule(alertRuleId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<AlertRuleParameter>) =>
      client(`/alert-rule/${alertRuleId}`, {
        method: 'PUT',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ALERT_RULES_QUERY_KEY);
      },
    }
  );
}
