import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowsProp } from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { useShops } from '../../api/hooks/useShopApi';
import { useCrudPageUiStateContext } from '../../context/CrudPageUiContext';
import { ActionTableButton } from '../library/table/action-buttons/ActionTableButton';
import { DataTableColumn } from '../library/table/DataTable';

export function ShopsTable() {
  const { isLoading, shops } = useShops();
  const intl = useIntl();
  const {
    setOpenCreateDeviceDialog,
    setParentEntityToCreate,
    setEntitieIdsToDelete,
    setOpenDeleteDialog,
  } = useCrudPageUiStateContext();

  const rows: GridRowsProp = shops.flatMap(shop => [
    { id: shop.name, name: shop.name, type: 'shop', _id: shop.id },
    ...shop.devices.map(device => ({
      id: `${shop.name}-${device.name}`,
      _id: device.id,
      name: device.name,
      parentName: shop.name,
      type: 'device',
    })),
  ]);

  function getActionButtons(rowId: string): ReactNode {
    const row = rows.find(row => row.id === rowId);

    if (!row) {
      return <></>;
    }

    if (row.parentName) {
      return (
        <ActionTableButton
          id={'deleteDevice_' + row.name}
          onClick={() => {
            // Find the parent shop
            const parentShop = shops.find(shop => shop.name === row.parentName);

            // Check if the parent shop exists
            if (parentShop) {
              // Get the parent shop ID and Device ID to delete
              setOpenDeleteDialog(true);
              setEntitieIdsToDelete([parentShop.id, row._id]);
            } else {
              console.error('Parent shop not found');
            }
          }}
          icon={<DeleteIcon />}
        ></ActionTableButton>
      );
    } else {
      return (
        <>
          <ActionTableButton
            id={'AddDevice_' + row.name}
            onClick={() => {
              setOpenCreateDeviceDialog(true);
              setParentEntityToCreate(row._id);
            }}
            icon={<Add />}
          ></ActionTableButton>
          <ActionTableButton
            id={'DeleteShop_' + row.name}
            onClick={() => {
              setEntitieIdsToDelete([row._id, 'shop']);
              setOpenDeleteDialog(true);
            }}
            icon={<DeleteIcon />}
          ></ActionTableButton>
        </>
      );
    }
  }

  const columns: DataTableColumn[] = [
    {
      field: 'type',
      headerName: 'Typ',
      flex: 1,
      sortable: false,
      renderCell: param => {
        return param.row.type === 'shop'
          ? intl.formatMessage({ id: 'shop.self' })
          : intl.formatMessage({ id: 'shop.device' });
      },
    },
    actionColumnDefinition({ getActionButtons, intl }),
  ];

  return (
    <div>
      <DataGridPremium
        loading={isLoading}
        rows={rows}
        columns={columns.map(column => mapColumn(column))}
        getRowId={row => row.id}
        treeData
        getTreeDataPath={row => (row.parentName ? [row.parentName, row.name] : [row.name])}
        autosizeOnMount
        pagination
        autoHeight={true}
        disableRowSelectionOnClick
        disableColumnMenu
        sx={getDataTableStyle()}
      />
    </div>
  );
}

function mapColumn(column: DataTableColumn): GridColDef {
  return { ...column };
}

interface ActionColumnDefinitionParams {
  getActionButtons: (rowId: string) => ReactNode;
  intl: IntlShape;
}

function actionColumnDefinition({
  getActionButtons,
  intl,
}: ActionColumnDefinitionParams): DataTableColumn {
  return {
    field: 'action',
    headerName: intl.formatMessage({ id: 'general.actions' }),
    sortable: false,
    flex: 1,
    renderCell: params => {
      return (
        <>
          <Stack direction="row">{getActionButtons(params.row.id)}</Stack>
        </>
      );
    },
  };
}

function getDataTableStyle() {
  return {
    bgcolor: 'white',
  };
}
