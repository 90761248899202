import { Button } from '@mui/material';
import { ReactNode } from 'react';
interface FullWidthButtonProps {
  children: ReactNode;
  onClick?: () => void;
}

export function FullWidthButton({ children, onClick }: FullWidthButtonProps) {
  return (
    <Button onClick={onClick} sx={{ my: 1 }} fullWidth variant="contained">
      {children}
    </Button>
  );
}
