import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

interface DialogProps {
  id: string;
  title: string;
  isOpen: boolean;
  content: ReactNode;
  buttons?: ReactNode;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export function Dialog({ id, title, isOpen, content, buttons, width = 'sm' }: DialogProps) {
  return (
    <MuiDialog id={id} open={isOpen} keepMounted maxWidth={width}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </MuiDialog>
  );
}
