import { useOktaAuth } from '@okta/okta-react';

import { useCurrentUserContext } from '../../../context/auth/CurrentUserContext';
import { SubHeaderMenu } from '../SubHeaderMenu';

interface UserMenuProps {
  closeMenu: () => void;
}

function UserMenu({ closeMenu }: UserMenuProps) {
  const { userName } = useCurrentUserContext();
  const { oktaAuth } = useOktaAuth();

  return (
    <SubHeaderMenu
      firstButtonLabel={userName}
      isFirstButtonDisabled={true}
      secondButtonLabel="Logout"
      handleSecondButtonClick={() => oktaAuth.signOut()}
      closeMenu={closeMenu}
    />
  );
}

export { UserMenu };
