import axios from 'axios';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface DynamicConfig {
  REACT_APP_BACKOFFICE_API_URL: string;
  OKTA_LOGIN_URL: string;
  OKTA_BACKOFFICE_CLIENT_ID: string;
  OKTA_BACKOFFICE_REDIRECT_URI: string;
}

interface DynamicConfigContextApi {
  config: DynamicConfig;
  setConfig: (newConfig: DynamicConfig) => void;
}

const globalConfigUrl = `${process.env.REACT_APP_BASE_ROUTE as string}config/config.json`;

const ConfigContext = createContext<DynamicConfigContextApi>({
  config: {
    REACT_APP_BACKOFFICE_API_URL: '',
    OKTA_LOGIN_URL: '',
    OKTA_BACKOFFICE_CLIENT_ID: '',
    OKTA_BACKOFFICE_REDIRECT_URI: '',
  },
  setConfig: () => {},
});

interface ConfigProviderProps {
  children: ReactNode;
}

function ConfigProvider({ children }: ConfigProviderProps) {
  const [configState, setConfigState] = useState<DynamicConfig>({
    REACT_APP_BACKOFFICE_API_URL: '',
    OKTA_LOGIN_URL: '',
    OKTA_BACKOFFICE_CLIENT_ID: '',
    OKTA_BACKOFFICE_REDIRECT_URI: '',
  });

  const { data, isSuccess } = useQuery('config', () =>
    axios.get(globalConfigUrl).then(response => {
      return response.data;
    })
  );

  useEffect(() => {
    setConfigState(data);
  }, [data]);

  const value: DynamicConfigContextApi = {
    config: configState,
    setConfig: setConfigState,
  };

  if (isSuccess && configState) {
    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
  }
  return <></>;
}

function useConfigContext() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

export { ConfigProvider, useConfigContext };
