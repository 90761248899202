import { ReactNode, createContext, useContext, useState } from 'react';

interface DialogStateContextApi {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

interface DialogStateProvider {
  children: ReactNode;
}

function DialogStateProvider({ children }: DialogStateProvider) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const value: DialogStateContextApi = {
    openDialog,
    setOpenDialog,
  };
  return <DialogStateContext.Provider value={value}>{children}</DialogStateContext.Provider>;
}
const DialogStateContext = createContext<DialogStateContextApi | undefined>(undefined);

function useDialogStateContext() {
  const context = useContext<DialogStateContextApi | undefined>(DialogStateContext);

  if (context === undefined) {
    throw new Error('useDialogStateContext must be used within an DialogStateProvider');
  }
  return context;
}

export { DialogStateProvider, useDialogStateContext };
