import { CreateEntityButton } from '../../components/common/crud/CreateEntityButton';
import { SimpleNotification } from '../../components/common/notification/SimpleNotification';
import { CreateDeviceDialog } from '../../components/shops/create/CreateDeviceDialog';
import { CreateShopDialog } from '../../components/shops/create/CreateShopDialog';
import { DeleteDeviceEntityDialog } from '../../components/shops/delete/DeleteDeviceEntityDialog';
import { DeleteShopEntityDialog } from '../../components/shops/delete/DeleteShopEntityDialog';
import { ShopsPageHeader } from '../../components/shops/ShopsPageHeader';
import { ShopsTable } from '../../components/shops/ShopsTable';
import { CrudPageUiStateProvider } from '../../context/CrudPageUiContext';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

function ShopsPage() {
  return (
    <NotificationMessageProvider>
      <CrudPageUiStateProvider>
        <ShopsPageHeader>
          <CreateEntityButton />
        </ShopsPageHeader>
        <ShopsTable />
        <CreateShopDialog />
        <CreateDeviceDialog />
        <DeleteShopEntityDialog />
        <DeleteDeviceEntityDialog />
        <SimpleNotification />
      </CrudPageUiStateProvider>
    </NotificationMessageProvider>
  );
}
export { ShopsPage };
