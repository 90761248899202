import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { OpenLockerData } from '../../modules/stations/cabinets/lockers/OpenLockerButton';
import { CabinetFormData } from '../../modules/stations/cabinets/manage/CabinetForm';
import { StationFormData } from '../../modules/stations/manage/StationForm';
import {
  Cabinet,
  CabinetType,
  EditRequest,
  Locker,
  MessageObject,
  PairIotAdapterParams,
  Station,
  TerminalPairingCode,
} from '../../types/types';

const STATIONS_ROUTE = '/stations';
const CABINETS_ROUTE = '/cabinets';
const LOCKERS_ROUTE = '/lockers';

function useStations() {
  const client = useClient<Station[]>();
  const result = useQuery('stations', () => client(STATIONS_ROUTE));
  return { ...result, stations: result.data?.data ?? [] };
}

function useCreateStation() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: StationFormData) =>
      client(`${STATIONS_ROUTE}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

function useEditStation() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: StationFormData) =>
      client(`${STATIONS_ROUTE}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

function useDeleteStation(stationId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${STATIONS_ROUTE}/${stationId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

function useCabinets(stationId: string) {
  const client = useClient<Cabinet[]>();
  const result = useQuery(['cabinets', stationId], () =>
    client(`${STATIONS_ROUTE}/${stationId}/cabinets`)
  );
  return { ...result, cabinets: result.data?.data ?? [] };
}

function useCreateCabinet(stationId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    (data: CabinetFormData) =>
      client(`${STATIONS_ROUTE}/${stationId}/cabinets`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cabinets');
      },
    }
  );
}

function useEditCabinet(stationId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${STATIONS_ROUTE}/${stationId}/cabinets?station_id=${stationId}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cabinets');
        queryClient.invalidateQueries('lockers');
      },
    }
  );
}

function useEditLocker() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${LOCKERS_ROUTE}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cabinets');
        queryClient.invalidateQueries('lockers');
      },
    }
  );
}

function useDeleteCabinet(stationId: string, cabinetId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${STATIONS_ROUTE}/${stationId}/cabinets/${cabinetId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cabinets');
      },
    }
  );
}

function useLockerSizeOptions() {
  const client = useClient<CabinetType[]>();
  const result = useQuery('cabinetTypes', () => client(CABINETS_ROUTE + '/types'));
  return { ...result, lockerSizeOptions: result.data?.data ?? [] };
}

function useLockers(stationId: string, cabinetId: string) {
  const client = useClient<Locker[]>();
  const result = useQuery(['lockers', stationId, cabinetId], () =>
    client(`${CABINETS_ROUTE}/${cabinetId}/lockers`)
  );
  return { ...result, lockers: result.data?.data ?? [] };
}

interface OpenedLocker {
  cabinetPositionLeftToRight: number;
  lockerPositionTopToBottom: number;
}

function useOpenLocker() {
  const client = useClient<OpenedLocker>();
  const queryClient = useQueryClient();
  return useMutation(
    (data: OpenLockerData) =>
      client(`${LOCKERS_ROUTE}/service-open`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lockers');
        queryClient.invalidateQueries('items');
      },
    }
  );
}

function usePairIotAdapter() {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    (updates: PairIotAdapterParams) =>
      client(`${STATIONS_ROUTE}/${updates.stationId}/pair-adapter/${updates.iotAdapterId}`, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

function useUnpairIotAdapter() {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    (stationId: string) =>
      client(`${STATIONS_ROUTE}/${stationId}/unpair-adapter`, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

function usePairTerminal() {
  const client = useClient<TerminalPairingCode>();
  return useMutation((stationId: string) =>
    client(`${STATIONS_ROUTE}/${stationId}/generate-pair-terminal-code`, {
      method: 'POST',
    })
  );
}

function useUnpairTerminal() {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    (stationId: string) =>
      client(`${STATIONS_ROUTE}/${stationId}/unpair-terminal`, {
        method: 'POST',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stations');
      },
    }
  );
}

export {
  useStations,
  useCreateStation,
  useEditStation,
  useDeleteStation,
  useCabinets,
  useCreateCabinet,
  useEditCabinet,
  useEditLocker,
  useDeleteCabinet,
  useLockerSizeOptions,
  useLockers,
  useOpenLocker,
  usePairIotAdapter,
  useUnpairIotAdapter,
  usePairTerminal,
  useUnpairTerminal,
};
