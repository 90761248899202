import { useOktaAuth } from '@okta/okta-react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface AuthContextApi {
  userEmail: string;
  userName: string;
}

const AuthContext = createContext<AuthContextApi | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

function CurrentUserProvider({ children }: AuthProviderProps) {
  const { authState } = useOktaAuth();
  const [userEmail, setUserEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const value: AuthContextApi = {
    userEmail,
    userName,
  };

  useEffect(() => {
    setUserEmail((authState?.idToken?.claims?.email_address as string) ?? '');
    setUserName(authState?.idToken?.claims?.name ?? '');
  }, [authState]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useCurrentUserContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}

export { CurrentUserProvider, useCurrentUserContext };
