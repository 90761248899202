import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import { EditActionTableButton } from '../../library/table/action-buttons/EditActionTableButton';

export function entityEditButtonFactoryMethod(id: string,entity: any | undefined) {
  return <>{entity && <EditEntityButton id={id} entity={entity} />}</>;
}
interface EditEntityButtonProps {
  id: string,
  entity: any;
}
export function EditEntityButton({id, entity }: EditEntityButtonProps) {
  const { setEntityToEdit } = useCrudPageUiStateContext();
  function handleEditEntity() {
    setEntityToEdit(entity);
  }
  return <EditActionTableButton id={`editEntity_${id}`} onClick={handleEditEntity} />;
}
