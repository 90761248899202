import { useFormContext } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';

import { useGetAlertRuleTemplates } from '../../../../api/hooks/useAlertRulesApi';
import { AlertRuleTemplate } from '../../../../types/alert-rules/AlertRuleTemplate';
import { AlertRuleType } from '../../../../types/alert-rules/AlertRuleType';
import { AlertRuleUnit } from '../../../../types/alert-rules/AlertRuleUnit';
import { AlertRuleUserInputUnit } from '../../../../types/alert-rules/AlertRuleUserInputUnit';
import { FormNumberAndUnitInput } from '../../../library/form/FormNumberAndUnitInput';
import { DropDownItem } from '../../../library/inputs/DropDown';

export function AlertRuleThresholdInput() {
  const intl = useIntl();
  const {alertRuleTemplates} = useGetAlertRuleTemplates();
  const { watch } = useFormContext();
  const alertRuleType = watch('alertRuleType');
  const thresholdPlaceholder = intl.formatMessage({
    id: 'alert-rules.threshold',
  });
  const unitDropDownItems = alertTypeUnitDrowDownItems(intl, alertRuleTemplates, alertRuleType)
  return (
    <FormNumberAndUnitInput
      numberId={'alertRuleThresholdInput'}
      numberName={'threshold'}
      numberPlaceHolder={thresholdPlaceholder}
      unitId={'alertRuleUnitInput'}
      unitName={'unit'}
      unitDropDownItems={unitDropDownItems}
      required={true}
    />
  );
}
function alertTypeUnitDrowDownItems(intl: IntlShape, alertRuleTemplates : AlertRuleTemplate[], alertRuleType: AlertRuleType): DropDownItem[] {
  const alertRuleTemplate = alertRuleTemplates.find( (at) => {return at.alertRuleType === alertRuleType});
  switch(alertRuleTemplate?.alertRuleUnit){
    case AlertRuleUnit.Days:
      return mapToDropDownUnits(intl,[AlertRuleUserInputUnit.Days]);
    case AlertRuleUnit.Minutes:
      return mapToDropDownUnits(intl, [
            AlertRuleUserInputUnit.Days,
            AlertRuleUserInputUnit.Hours,
            AlertRuleUserInputUnit.Minutes,
          ]);
    case AlertRuleUnit.Items:
      return mapToDropDownUnits(intl,[AlertRuleUserInputUnit.Items]);
    default:
      return mapToDropDownUnits(intl, [
        AlertRuleUserInputUnit.Days,
        AlertRuleUserInputUnit.Hours,
        AlertRuleUserInputUnit.Minutes,
      ]);
  }
}
function mapToDropDownUnits(intl : IntlShape, units : AlertRuleUserInputUnit[]) : DropDownItem[]{
  return units.map(unit => ({
    value: unit.toString(),
    label: intl.formatMessage({ id: `alert-rules.unit-${unit.toString()}` }),
  }));
}
