import { Paper as MuiPaper } from '@mui/material';
import { ReactNode } from 'react';

export interface PaperProps {
  children?: ReactNode;
  style?: any;
}
export function Paper({ children, style }: PaperProps) {
  return (
    <MuiPaper variant={'outlined'} style={style}>
      {children}
    </MuiPaper>
  );
}
