import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface ShopsPageHeaderProps {
  children?: ReactNode;
}

function ShopsPageHeader({ children }: ShopsPageHeaderProps) {
  const intl = useIntl();
  return <PageHeader breadCrumbs={shopsPageBreadCrumbs(intl)}>{children}</PageHeader>;
}
function shopsPageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '#',
    },
    {
      label: intl.formatMessage({ id: 'shops.self' }),
      route: '#/shops',
    },
  ];
}
export { ShopsPageHeader };
