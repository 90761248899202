import './scss/index.scss';
import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';

import { App } from './components/app/App';
import { AppProviders } from './context/AppProviders';
import { ConfigProvider } from './shared/configContext';

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY || '');

const container = document.getElementById('root');
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});
if (container !== null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <AppProviders>
              <App />
            </AppProviders>
          </ConfigProvider>
        </QueryClientProvider>
      </HashRouter>
    </React.StrictMode>
  );
} else {
  console.error("No element with id 'root' found");
}
