import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { ItemTypeFormData } from '../../modules/renting/item-types/manage/ItemTypeForm';
import { DetailedItemType, GdcCalibrationResult, ItemType } from '../../types/item-types/ItemType';
import { Renter } from '../../types/renter/Renter';
import { RenterParameter } from '../../types/renter/RenterParameter';
import { MessageObject, ServiceType, EditRequest, DetailedRenter } from '../../types/types';

const ITEM_TYPES_URL = '/item-types';
export const RENTERS_URL = '/renters';
const ITEM_TYPES_QUERY_KEY = 'itemTypes';
const ITEM_TYPES_DETAILS_QUERY_KEY = 'item-type-details';
const ITEM_TYPES_IMAGE_QUERY_KEY = 'item-type-image';
export const RENTERS_QUERY_KEY = 'renters';

function useItemTypes(enabled = true) {
  const client = useClient<ItemType[]>();
  const result = useQuery(ITEM_TYPES_QUERY_KEY, () => client(ITEM_TYPES_URL), {
    enabled: enabled,
  });
  return { ...result, itemTypes: result.data?.data ?? [] };
}

function useItemTypeDetails(itemTypeId: string | undefined) {
  const client = useClient<DetailedItemType>();
  const result = useQuery(
    ITEM_TYPES_DETAILS_QUERY_KEY,
    () => client(`${ITEM_TYPES_URL}/${itemTypeId}/details`),
    { enabled: !!itemTypeId }
  );
  return { ...result, detailedItemType: result.data?.data ?? null };
}

export function useItemTypeImage(itemTypeId: string | undefined) {
  const client = useClient<Blob>();
  
  const result = useQuery(
    [ITEM_TYPES_IMAGE_QUERY_KEY, itemTypeId],
    async () => {
      if (!itemTypeId) throw new Error('ItemTypeId is required');
      const response = await client(`${ITEM_TYPES_URL}/${itemTypeId}/image`, { responseType: 'blob' });
      return response;
    },
    {
      enabled: !!itemTypeId,
      select: (response) => URL.createObjectURL(response.data),
    }
  );
  return { ...result, imageUrl: result.data };
}

function useItemTypeServiceTypes(itemTypeId: string | undefined) {
  const client = useClient<ServiceType[]>();
  const result = useQuery(
    ['services', itemTypeId],
    () => client(`${ITEM_TYPES_URL}/${itemTypeId}/services`),
    { enabled: !!itemTypeId }
  );
  return {
    ...result,
    serviceTypes: result.data?.data ?? [],
  };
}

function useCreateItemType() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ItemTypeFormData) =>
      client(`${ITEM_TYPES_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ITEM_TYPES_QUERY_KEY);
      },
    }
  );
}

function useEditItemType() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${ITEM_TYPES_URL}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ITEM_TYPES_QUERY_KEY);
        queryClient.invalidateQueries(ITEM_TYPES_DETAILS_QUERY_KEY);
      },
    }
  );
}

function useDeleteItemType(itemTypeId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${ITEM_TYPES_URL}/${itemTypeId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ITEM_TYPES_QUERY_KEY);
      },
    }
  );
}

function useRenters() {
  const client = useClient<Renter[]>();
  const result = useQuery(RENTERS_QUERY_KEY, () => client(RENTERS_URL));
  return { ...result, renters: result.data?.data ?? [] };
}

function useRenterDetails(renterId: string | undefined) {
  const client = useClient<DetailedRenter>();
  const result = useQuery('renter-details', () => client(`${RENTERS_URL}/${renterId}/details`), {
    enabled: !!renterId,
  });
  return { ...result, detailedRenter: result.data?.data ?? null };
}

function useCreateRenter() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<RenterParameter>) =>
      client(`${RENTERS_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENTERS_QUERY_KEY);
      },
    }
  );
}

function useUploadTemplate() {
  const client = useClient<BlobPart>();
  const result = useQuery(
    'upload-template',
    () => client(`${RENTERS_URL}/xlsx`, { responseType: 'blob' }),
    { enabled: false }
  );
  return {
    ...result,
    uploadTemplate: result.data?.data ?? null,
    headers: result.data?.headers ?? null,
  };
}

function useUploadRenters() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (data: FormData) =>
      client(`${RENTERS_URL}/xlsx`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENTERS_QUERY_KEY);
      },
    }
  );
}

function useEditRenter() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${RENTERS_URL}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENTERS_QUERY_KEY);
      },
    }
  );
}

function useDeleteRenter(renterId: string) {
  const client = useClient<MessageObject>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${RENTERS_URL}/${renterId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENTERS_QUERY_KEY);
      },
    }
  );
}

function useDeleteMultiRenter() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { rentersId: { _id: string }[] }) =>
      client(`${RENTERS_URL}/`, {
        method: 'DELETE',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENTERS_QUERY_KEY);
      },
    }
  );
}

function useGdcCalibrationDate(itemId: string | undefined) {
  const client = useClient<GdcCalibrationResult>();
  const result = useQuery(
    ['gdc', itemId],
    () => client(`gdc/x-dock-test-results/${itemId}/calibration`),
    { enabled: !!itemId }
  );
  return { ...result, gdcCalibrationTestResponse: result.data?.data ?? null };
}

export {
  useItemTypes,
  useItemTypeDetails,
  useItemTypeServiceTypes,
  useCreateItemType,
  useEditItemType,
  useDeleteItemType,
  useRenters,
  useRenterDetails,
  useCreateRenter,
  useEditRenter,
  useUploadTemplate,
  useUploadRenters,
  useDeleteRenter,
  useDeleteMultiRenter,
  useGdcCalibrationDate,
};
