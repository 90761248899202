import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { ServiceTypeFormData } from '../../modules/service-types/manage/ServiceTypeForm';
import {
  ServiceType,
  EditRequest,
  ServiceTypeReferences,
  BasicServiceType,
} from '../../types/types';

const SERVICE_TYPES_URL = '/service-types';
const ALL_SERVICE_TYPES_URL = '/all-service-types';

function useServiceTypes() {
  const client = useClient<ServiceType[]>();
  const result = useQuery('service-types', () => client(SERVICE_TYPES_URL));
  return { ...result, serviceTypes: result.data?.data ?? [] };
}

function useAllServiceTypes() {
  const client = useClient<BasicServiceType[]>();
  const result = useQuery('all-service-types', () => client(ALL_SERVICE_TYPES_URL));
  return { ...result, allServiceTypes: result.data?.data ?? [] };
}

function useCreateServiceType() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<ServiceTypeFormData>) =>
      client(`${SERVICE_TYPES_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('service-types');
      },
    }
  );
}

function useEditServiceType() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(`${SERVICE_TYPES_URL}`, {
        method: 'PATCH',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('service-types');
      },
    }
  );
}

function useDeleteServiceType(serviceTypeId: string) {
  const client = useClient<ServiceTypeReferences>();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`service-types/${serviceTypeId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('service-types');
      },
    }
  );
}

function useServiceTypeReferences(serviceTypeId: string) {
  const client = useClient<ServiceTypeReferences>();
  const result = useQuery(
    'service-type-references',
    () => client(`service-types/${serviceTypeId}/references`),
    {
      enabled: false,
    }
  );
  return { ...result, serviceTypeReferences: result.data?.data ?? null };
}

export {
  useServiceTypes,
  useCreateServiceType,
  useEditServiceType,
  useServiceTypeReferences,
  useDeleteServiceType,
  useAllServiceTypes,
};
