import { CreateEntityButton } from '../../components/common/crud/CreateEntityButton';
import { SystemsPageProvider } from '../../components/systems/contexts/SystemsPageContext';
import { CreateSystemDialog } from '../../components/systems/create/CreateSystemDialog';
import { EditSystemDialog } from '../../components/systems/edit/EditSystemDialog';
import { SystemsPageHeader } from '../../components/systems/SystemsPageHeader';
import { SystemsTable } from '../../components/systems/SystemsTable';
import { CrudPageUiStateProvider } from '../../context/CrudPageUiContext';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

export function SystemsPage() {
  return (
    <>
      <NotificationMessageProvider>
        <CrudPageUiStateProvider>
          <SystemsPageProvider>
            <SystemsPageHeader>
              <CreateEntityButton />
            </SystemsPageHeader>
            <SystemsTable />
            <CreateSystemDialog />
            <EditSystemDialog />
          </SystemsPageProvider>
        </CrudPageUiStateProvider>
      </NotificationMessageProvider>
    </>
  );
}
