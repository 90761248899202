import dayjs from 'dayjs';

import { useEditRenter } from '../../../api/hooks/useRentingApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { Renter } from '../../../types/renter/Renter';
import { RenterFormValues } from '../../../types/renter/RenterFormValues';
import { RenterParameter } from '../../../types/renter/RenterParameter';
import {
  ApiError,
  EditOperation,
  EditRequest,
  hasPeriodOfValidity,
  Qualification,
} from '../../../types/types';
import { EditEntityDialogCloseButton } from '../../common/crud/EditEntityDialogCloseButton';
import { FormSubmitButton } from '../../library/buttons/FormSubmitButton';
import { apiFormat } from '../../library/inputs/DatePicker';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';
import { useRentersPageContext } from '../contexts/RentersPageContext';
import { RenterForm } from '../form/RenterForm';

export function PatchRenterForm() {
  const { entityToEdit: renterToEdit, setEntityToEdit } = useCrudPageUiStateContext<Renter>();
  const { qualifications, qualificationsIsLoading } = useRentersPageContext();
  const { setNotificationMessage } = useNotificationMessageContext();
  const {
    mutateAsync: editRenter,
    reset: resetEditRenter,
    isLoading: patchRenterIsLoading,
  } = useEditRenter();
  const isLoading = patchRenterIsLoading || qualificationsIsLoading;
  const formValues = getInitialFormValues(renterToEdit, qualifications);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <RenterForm
          initialFormValues={formValues}
          handleSubmit={handleSubmit}
          buttons={EditRenterFormButtons()}
        />
      )}
    </>
  );
  function handleSubmit(param: RenterParameter, dirtyFields: string[]) {
    const patchObject = createPatchRequest(renterToEdit?._id ?? '', param, dirtyFields);
    editRenter(patchObject, {
      onSuccess: () => {
        resetEditRenter();
        setNotificationMessage('crud.edited', NotificationType.Success);
        setEntityToEdit(undefined);
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          const errorData = error.response.data as ApiError;
          if (errorData.code) {
            setNotificationMessage(`${errorData.code}`, NotificationType.Error);
          } else {
            setNotificationMessage('crud.generic-edit-error', NotificationType.Error);
          }
        }
      },
    });
  }
}
function EditRenterFormButtons() {
  return (
    <>
      <EditEntityDialogCloseButton />
      <FormSubmitButton />
    </>
  );
}

function createPatchRequest<T>(
  entityId: string,
  formValues: T,
  dirtyFields: string[]
): EditRequest {
  const patchFields = dirtyFields.map(field => {
    const operation = (typeof formValues[field as keyof T]) === 'boolean' || !!formValues[field as keyof T]? EditOperation.replace : EditOperation.remove;
    const value = operation === EditOperation.replace ? formValues[field as keyof T] : undefined;
    return { field, operation, value };
  });
  return {
    _id: entityId,
    patchFields,
  };
}
function getInitialFormValues(
  renter: Renter | undefined,
  qualifications: Qualification[]
): RenterFormValues {
  if (!renter) throw new Error();
  const formattedQualiDetails = renter.qualificationDetails.map(qd => {
    const qualification = qualifications.find(q => q._id === qd._id);
    return {
      _id: qd._id,
      issueDate: hasPeriodOfValidity(qualification)
        ? dayjs(qd.issueDate, 'DD.MM.YYYY').format(apiFormat)
        : null,
      expiryDate: !hasPeriodOfValidity(qualification)
        ? dayjs(qd.expiryDate, 'DD.MM.YYYY').format(apiFormat)
        : null,
    };
  });
  return {
    firstName: renter.firstName,
    lastName: renter.lastName,
    barcode: renter.barcode,
    humanRenterId: renter.humanRenterId ?? '',
    contractorId: renter.contractor._id,
    phone: renter.phone ?? '',
    email: renter.email ?? '',
    supervisorEmails: renter.supervisorEmails ?? [],
    qualificationOptions: renter.qualificationDetails.map(q => q._id),
    qualifications: formattedQualiDetails,
    isRenterAdmin: renter.isRenterAdmin,
    status: renter.status,
  };
}
