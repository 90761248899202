import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { CreateUserFormData } from '../../modules/users/create-user/CreateUserForm';
import { User, DetailedUser, EditRequest } from '../../types/types';

const USERS_URL = '/users';
const SYSTEM_USERS_URL = '/system-users';
const USERS_QUERY_KEY = 'users';

function useUsers() {
  const client = useClient<User[]>();
  const result = useQuery(USERS_QUERY_KEY, () => client(USERS_URL));

  return { ...result, users: result.data?.data ?? [] };
}

function useCreateUser() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<CreateUserFormData>) =>
      client(USERS_URL, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USERS_QUERY_KEY);
      },
    }
  );
}

function usePatchUser() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: EditRequest) =>
      client(USERS_URL, {
        method: 'PATCH',
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USERS_QUERY_KEY);
      },
    }
  );
}

function useDeleteUserFromSystem(userId: string, rrSystemId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${SYSTEM_USERS_URL}/${userId}/roles/${rrSystemId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users-by-system');
      },
    }
  );
}

function useDeleteUser(userId: string) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${USERS_URL}/${userId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    }
  );
}

function useUsersBySystem() {
  const client = useClient<User[]>();
  const result = useQuery('users-by-system', () => client(`${SYSTEM_USERS_URL}`));
  return { ...result, users: result.data?.data ?? [] };
}

function useCreateUserInSystem() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: Partial<CreateUserFormData>) =>
      client(`${SYSTEM_USERS_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users-by-system');
      },
    }
  );
}

function useUserDetails(userId: string) {
  const client = useClient<DetailedUser>();
  const result = useQuery('user-details', () => client(`${USERS_URL}/${userId}/details`));
  return { ...result, userDetails: result.data?.data ?? null };
}

export {
  useUsers,
  useDeleteUserFromSystem,
  useDeleteUser,
  useCreateUser,
  useUsersBySystem,
  useCreateUserInSystem,
  useUserDetails,
  usePatchUser,
};
