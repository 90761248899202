import { useEffect, useState } from 'react';
import { useForm, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useItemTypeImage } from '../../../../api/hooks/useRentingApi';
import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../../components/form/LabeledDropdown';
import { LabeledImageInput } from '../../../../components/form/LabeledImageInput';
import { LabeledInput } from '../../../../components/form/LabeledInput';
import { LabeledSwitch } from '../../../../components/form/LabeledSwitch';
import { MultiSelectDropdown } from '../../../../components/form/MultiSelectDropdown';
import { ItemType, XDockTestWorkflowPolicy } from '../../../../types/item-types/ItemType';
import { Manufacturer, OptionItem } from '../../../../types/types';
import { getDirtyFormValues, getValueFromOptions } from '../../../../utils/formData';

import { GdcSpecificationsForm } from './GdcEspecificationsForm';

export interface ItemTypeFormProps {
  onCancel: () => void;
  onSubmit: (data: Record<string, any>) => void;
  lockerSizes: OptionItem[];
  lockerFeatures: OptionItem[];
  qualifications: OptionItem[];
  serviceTypes: OptionItem[];
  rentalCategories: OptionItem[];
  manufacturerCategories: OptionItem[];
  itemType?: ItemType;
  selectedServiceTypes?: OptionItem[];
  selectedQualifications?: OptionItem[];
  cleanup: () => void;
}

export interface ItemTypeFormData {
  _id?: string;
  name: string;
  image?: Image | null;
  imageName?: string | null;
  imageContent?: string | null;
  lockerSize: string;
  lockerFeatureId: string | null;
  qualificationIds: string[] | string;
  xDockTestable: boolean;
  isConsumable: boolean;
  xDockTestWorkflowPolicy: XDockTestWorkflowPolicy;
  xDockTestExpirationTime: number;
  chargingDurationInHours: number;
  xDockTestExpirationTimeInStorage: number;
  gdcCalibration: boolean;
  gdcCalibrationInterval: number;
  gdcCalibrationBlockPeriod: number | string;
  serviceTypeIds: string[] | string;
  xDockAlertingTime: number;
  shortName: string | null;
  rentalCategory: string;
  rentalErpId: string;
  mainErpId: string;
  manufacturer: string;
}

export interface Image {
  name: string;
  content: string;
}

export interface RawItemTypeData {
  name: string;
  images: FileList;
  imageUrl?: string;
  lockerSize: string;
  lockerFeatureId: string | null;
  qualifications: OptionItem[];
  chargingDurationInHours: number;
  xDockTestable: boolean;
  isConsumable: boolean;
  xDockTestWorkflowPolicy: XDockTestWorkflowPolicy;
  xDockTestExpirationTime: number;
  xDockTestExpirationTimeInStorage: number;
  xDockAlertingTime: number;
  gdcCalibration: boolean;
  gdcCalibrationInterval: number;
  gdcCalibrationBlockPeriod: number;
  serviceTypes: OptionItem[];
  shortName: string | null;
  rentalCategory: string;
  rentalErpId: string;
  mainErpId: string;
  manufacturer: string;
}

const NAME_LENGTH_UNTIL_SHORTNAME_IS_REQUIRED = 26;
const MAX_IMAGE_SIZE = 5;

function ItemTypeForm({
  onCancel,
  onSubmit,
  lockerFeatures,
  lockerSizes,
  serviceTypes,
  qualifications,
  rentalCategories,
  manufacturerCategories,
  itemType,
  selectedServiceTypes,
  selectedQualifications,
  cleanup,
}: ItemTypeFormProps) {
  const intl = useIntl();
  const isEditForm = !!itemType;
  const [showImage, toggleShowImage] = useState<boolean>(true);
  const {imageUrl}  = useItemTypeImage(itemType?._id);
  const defaultItemTypeEditValues = (itemType: ItemType) => ({
    name: itemType.name,
    imageUrl: imageUrl,
    chargingDurationInHours: itemType.chargingDurationInHours,
    xDockTestable: itemType.xDockTestable,
    isConsumable: itemType.isConsumable,
    xDockAlertingTime: itemType.xDockAlertingTime,
    XDockTestWorkflowPolicy: itemType.xDockTestWorkflowPolicy,
    xDockTestExpirationTime: itemType.xDockTestExpirationTime,
    xDockTestExpirationTimeInStorage: itemType.xDockTestExpirationTimeInStorage,
    gdcCalibration: itemType.gdcCalibration,
    gdcCalibrationInterval: itemType.gdcCalibrationInterval,
    gdcCalibrationBlockPeriod: itemType.gdcCalibrationBlockPeriod,
    shortName: itemType.shortName,
    serviceTypes: selectedServiceTypes,
    qualifications: selectedQualifications,
    rentalCategory: itemType.rentalCategory,
    rentalErpId: itemType.rentalErpId,
    mainErpId: itemType.mainErpId,
    manufacturer: itemType.manufacturer,
  });

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { isValid, isDirty, dirtyFields, isSubmitting, errors },
  } = useForm<RawItemTypeData>({
    mode: 'onChange',
    defaultValues: itemType
      ? defaultItemTypeEditValues(itemType)
      : {
          chargingDurationInHours: 0,
          xDockTestable: false,
          gdcCalibration: false,
          isConsumable: false,
        },
  });

  const watchIsConsumableSwitch = watch('isConsumable');
  const watchXDockSwitch = watch('xDockTestable');
  const watchItemTypeName = watch('name');
  const watchItemTypeImages = watch('images');

  const isShortNameRequired = watchItemTypeName
    ? watchItemTypeName.length > NAME_LENGTH_UNTIL_SHORTNAME_IS_REQUIRED
    : false;

  const validateShortName = (shortName: string | null) => {
    if (!isShortNameRequired) {
      return true;
    }
    if (!shortName) return false;
    if (
      shortName.length <= NAME_LENGTH_UNTIL_SHORTNAME_IS_REQUIRED &&
      shortName.length < watchItemTypeName.length
    ) {
      return true;
    }

    return intl.formatMessage({ id: 'item-types.itemTypeShortName.error' });
  };

  const validateImageSize: Validate<FileList, any> = input => {
    if (input.length > 0) {
      const imageSizeInMbs = input[0].size / 1024 / 1024;
      return imageSizeInMbs < MAX_IMAGE_SIZE;
    }
    return true;
  };

  const handleImageChange = (imageContent: string) => {
    if (imageContent) {
      return toggleShowImage(false);
    }
    toggleShowImage(true);
  };

  const onChangeIsConsumableSwitch = (isChecked: boolean) => {
    if (isChecked) {
      setValue('manufacturer', Manufacturer.OTHER, {
        shouldValidate: true,
      });
      setValue('chargingDurationInHours', 0, {
        shouldValidate: true,
      });
      setValue('xDockTestable', false);
    }
    if (!isChecked) {
      resetField('manufacturer');
      resetField('chargingDurationInHours');
    }
  };

  const getManufacturerSelectedValue = () => {
    if (watchXDockSwitch) return Manufacturer.DRAEGER;
    else if (watchIsConsumableSwitch) return Manufacturer.OTHER;
    else return undefined;
  };

  const cleanFormData = (data: Record<string, any>) => {
    if (data.lockerFeatureId === 'none' && !isEditForm) {
      delete data.lockerFeatureId;
    }
    if (isEditForm) {
      delete data.manufacturer;
    }
    if (!data.xDockTestable) {
      data.gdcCalibration = false;
      delete data.xDockTestExpirationTime;
      delete data.xDockTestWorkflowPolicy;
      delete data.xDockTestExpirationTimeInStorage;
      delete data.xDockAlertingTime;
      delete data.gdcCalibrationInterval;
      delete data.gdcCalibrationBlockPeriod;
      return data;
    }
    if (!data.gdcCalibration) {
      delete data.gdcCalibrationInterval;
      delete data.gdcCalibrationBlockPeriod;
      return data;
    }
    if (data.isConsumable) {
      delete data.xDockTestable;
      delete data.xDockAlertingTime;
      delete data.xDockTestExpirationTimeInStorage;
      delete data.xDockTestExpirationTime;
      delete data.xDockTestWorkflowPolicy;
      delete data.gdcCalibration;
      delete data.gdcCalibrationBlockPeriod;
      delete data.gdcCalibrationInterval;
      delete data.serviceTypes;
      delete data.lockerFeatureId;
      delete data.qualifications;
    }
    return data;
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(data => {
          const cleanData = cleanFormData(data);

          if (isEditForm) {
            const dataDirty = getDirtyFormValues(cleanData, dirtyFields);
            onSubmit(dataDirty);
          } else {
            onSubmit(cleanData);
          }
        })}
      >
        <div className="uib-modal__content modal-form double-col">
          <LabeledSwitch
            id="isConsumable"
            label={intl.formatMessage({ id: 'item-types.consumable' })}
            isSelected={watchIsConsumableSwitch}
            disabled={isEditForm}
            props={{
              ...register('isConsumable', {
                required: false,
                onChange: e => onChangeIsConsumableSwitch(e.target.checked),
              }),
            }}
          />
          <LabeledDropdown
            label={intl.formatMessage({ id: 'item-types.rentalCategory' })}
            id="rentalCategory"
            options={rentalCategories}
            placeholder={
              !isEditForm
                ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' })
                : intl.formatMessage({
                    id: `item-types.category.${itemType?.rentalCategory}`,
                  })
            }
            setValue={setValue}
            props={{ ...register('rentalCategory', { required: !isEditForm }) }}
            disabled={isEditForm}
            dataTestId="rentalCategoryDropDown"
          />
          {!watchIsConsumableSwitch && (
            <LabeledSwitch
              id="xDockTestable"
              label={intl.formatMessage({ id: 'item-types.xDockTestable' })}
              isSelected={watchXDockSwitch && !watchIsConsumableSwitch}
              props={{
                ...register('xDockTestable', {
                  required: false,
                }),
              }}
            />
          )}
          <LabeledInput
            id="name"
            label={intl.formatMessage({ id: 'item-types.itemTypeName' })}
            placeholder={intl.formatMessage({ id: 'item-types.itemTypeName' })}
            props={{ ...register('name', { required: true }) }}
          />
          <LabeledImageInput
            label={intl.formatMessage({ id: 'item-types.image' })}
            itemType={itemType}
            isEditForm={isEditForm}
            showImage={showImage}
            imageName={!!watchItemTypeImages?.length && watchItemTypeImages[0].name}
            props={{
              ...register('images', {
                required: false,
                validate: validateImageSize,
                onChange: e => handleImageChange(e.target.value),
              }),
            }}
            errors={errors}
            errorMessage={intl.formatMessage({ id: 'item-types.image.error' })}
          />
          <LabeledInput
            id="shortName"
            label={intl.formatMessage({ id: 'item-types.itemTypeShortName' })}
            placeholder={intl.formatMessage({
              id: 'item-types.itemTypeShortName',
            })}
            required={isShortNameRequired}
            props={{
              ...register('shortName', {
                required: isShortNameRequired,
                validate: validateShortName,
              }),
            }}
            subContent={
              errors.shortName?.message ? (
                <span className="form-error">{errors.shortName.message}</span>
              ) : undefined
            }
          />
          {!watchIsConsumableSwitch && (
            <LabeledDropdown
              label={intl.formatMessage({ id: 'lockers.lockerFeature' })}
              id="lockerFeatureId"
              options={lockerFeatures}
              selectedValue={
                itemType && getValueFromOptions(lockerFeatures, itemType.lockerFeature)
              }
              required={false}
              setValue={setValue}
              props={{
                ...register('lockerFeatureId'),
              }}
            />
          )}
          <LabeledDropdown
            label={intl.formatMessage({ id: 'lockers.lockerSize' })}
            id="lockerSize"
            options={lockerSizes}
            selectedValue={itemType && getValueFromOptions(lockerSizes, itemType.lockerSize)}
            placeholder={
              !isEditForm ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' }) : undefined
            }
            setValue={setValue}
            sortOptions={false}
            props={{ ...register('lockerSize', { required: !isEditForm }) }}
            dataTestId="lockerSizeDropdown"
          />
          {!watchIsConsumableSwitch && (
            <>
              <MultiSelectDropdown
                label={intl.formatMessage({ id: 'qualifications.self' })}
                id="qualifications"
                options={qualifications}
                required={false}
                showPlaceholder={true}
                control={control}
                props={{ ...register('qualifications') }}
              />

              <MultiSelectDropdown
                label={intl.formatMessage({ id: 'service-types.self' })}
                id="serviceTypes"
                options={serviceTypes}
                required={false}
                showPlaceholder={!isEditForm}
                control={control}
                props={{ ...register('serviceTypes') }}
              />
              <LabeledInput
                id="charging-duration"
                label={intl.formatMessage({
                  id: 'item-types.chargingDuration',
                })}
                type="number"
                min="0"
                max="8"
                defaultValue="0"
                disabled={watchIsConsumableSwitch}
                props={{
                  ...register('chargingDurationInHours', { required: true }),
                }}
              />
            </>
          )}
          <LabeledDropdown
            label={intl.formatMessage({ id: 'manufacturer' })}
            id="manufacturer"
            options={manufacturerCategories}
            placeholder={
              !isEditForm
                ? intl.formatMessage({ id: 'general.pleaseChooseEllipsis' })
                : intl.formatMessage({
                    id: `item-types.manufacturer.${itemType.manufacturer}`,
                  })
            }
            setValue={setValue}
            props={{ ...register('manufacturer', { required: !isEditForm }) }}
            disabled={isEditForm || watchXDockSwitch || watchIsConsumableSwitch}
            selectedValue={getManufacturerSelectedValue()}
          />
          <LabeledInput
            id="rental-erp-id"
            label={intl.formatMessage({ id: 'item-types.rentalErpId' })}
            required={false}
            placeholder={intl.formatMessage({ id: 'item-types.rentalErpId' })}
            props={{
              ...register('rentalErpId'),
            }}
            disabled={isEditForm}
            tooltipMessage={intl.formatMessage({
              id: 'item-types.rentalErpIdInfoMessage',
            })}
          />
          <LabeledInput
            id="main-erp-id"
            label={intl.formatMessage({ id: 'item-types.mainErpId' })}
            required={false}
            placeholder={intl.formatMessage({ id: 'item-types.mainErpId' })}
            props={{
              ...register('mainErpId'),
            }}
            disabled={isEditForm}
            tooltipMessage={intl.formatMessage({
              id: 'item-types.mainErpIdInfoMessage',
            })}
          />
        </div>

        {watchXDockSwitch && (
          <GdcSpecificationsForm
            register={register}
            watch={watch}
            itemType={itemType}
            setValue={setValue}
          />
        )}
        <div className="uib-modal__footer">
          <DefaultButtonGroup
            isSubmitDisabled={
              isSubmitting || !isDirty || !isValid || !Object.keys(dirtyFields).length
            }
            onCancel={onCancel}
          />
        </div>
      </form>
    </>
  );
}

export { ItemTypeForm };
