import { useIntl } from 'react-intl';

import { FormTextField } from '../../../library/form/FormTextField';

export function ShopNameInput() {
  const intl = useIntl();

  return (
    <FormTextField
      id={'shopNameInput'}
      label={intl.formatMessage({ id: 'shop.name' })}
      required={true}
      disabled={false}
      name={'name'}
    />
  );
}
