import { useMemo } from 'react';
import SwaggerUI from 'swagger-ui-react';

import 'swagger-ui-react/swagger-ui.css';
import { useRRSystemContext } from '../../context/rrSystemContext';

interface ApiDocsTabProps {
  spec: any;
}
export function ApiDocsTab({ spec }: ApiDocsTabProps) {
  const { rrSystem } = useRRSystemContext();
  const updatedSpec = useMemo(() => {
    if (!spec || !rrSystem?._id) return spec;

    return addRRSystemId(spec, rrSystem._id);
  }, [spec, rrSystem]);
  return <>{spec && <SwaggerUI spec={updatedSpec} />}</>;
}
function addRRSystemId(obj: any, rrSystemId: string): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(o => addRRSystemId(o, rrSystemId));
  }
  const newObj = { ...obj };
  if (newObj.parameters && Array.isArray(newObj.parameters)) {
    newObj.parameters = newObj.parameters.map((param: any) => {
      if (param.name === 'rr_system_id' && param.in === 'query') {
        return { ...param, default: rrSystemId };
      }
      return param;
    });
  }
  for (const key in newObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (newObj.hasOwnProperty(key)) {
      newObj[key] = addRRSystemId(newObj[key], rrSystemId);
    }
  }

  return newObj;
}
