import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppContext } from '../../../context/appContext';
import { DropDownMenu } from '../../library/menu/DropDownMenu';
import { DropDownMenuItem } from '../../library/menu/DropDownMenuItem';

interface SettingsMenuProps {
  closeMenu: () => void;
  anchorEl: HTMLElement | null;
}

export function SettingsMenu({ closeMenu, anchorEl }: SettingsMenuProps) {
  const intl = useIntl();
  const { openSettingsModal } = useAppContext();
  return (
    <>
      <DropDownMenu anchorEl={anchorEl} id="settings-menu" closeMenu={closeMenu}>
        <DropDownMenuItem
          id={'appSettingBtn'}
          onClick={() => {
            openSettingsModal();
          }}
        >
          {intl.formatMessage({ id: 'app.settings' })}
        </DropDownMenuItem>
        <RouteMenuItem
          id={'userManagementBtn'}
          route={'/user-mgmt'}
          label={intl.formatMessage({ id: 'app.users.management' })}
        ></RouteMenuItem>
        <RouteMenuItem
          id={'systemsBtn'}
          route={'/systems'}
          label={intl.formatMessage({ id: 'systems.self' })}
        ></RouteMenuItem>
        <RouteMenuItem
          id={'apiDocsBtn'}
          route={'/developer'}
          label={intl.formatMessage({ id: 'api-docs.self' })}
        ></RouteMenuItem>
      </DropDownMenu>
    </>
  );
}
interface RouteMenuItemProps {
  id: string;
  route: string;
  label: string;
}
function RouteMenuItem({ id, route, label }: RouteMenuItemProps) {
  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();
  return (
    <>
      {currentRoute != route && (
        <DropDownMenuItem
          id={id}
          onClick={() => {
            navigate(route);
          }}
        >
          {label}
        </DropDownMenuItem>
      )}
    </>
  );
}
