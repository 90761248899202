import { useIntl } from 'react-intl';

import { useGetPairingCode } from '../../../api/hooks/useShopApi';
import { useCrudPageUiStateContext } from '../../../context/CrudPageUiContext';
import {
  NotificationType,
  useNotificationMessageContext,
} from '../../../context/notificationMessageContext';
import { ConfirmButton } from '../../library/buttons/ConfirmButton';
import { Dialog } from '../../library/dialog/Dialog';
import { LoadingIndicator } from '../../library/loading/LoadingIndicator';

function CreateDeviceDialog() {
  const intl = useIntl();
  const {
    openCreateDeviceDialog,
    setOpenCreateDeviceDialog,
    parentEntityToCreate,
    setParentEntityToCreate,
  } = useCrudPageUiStateContext();
  const { setNotificationMessage } = useNotificationMessageContext();
  const shopId = parentEntityToCreate?.toString();

  const { data, isLoading } = useGetPairingCode(shopId || '');

  return (
    <>
      {openCreateDeviceDialog && shopId && (
        <Dialog
          id={'CreateShopDeviceDialog'}
          isOpen={openCreateDeviceDialog}
          title={intl.formatMessage({ id: 'shop.createDevice' })}
          content={getDialogContent()}
          buttons={
            <ConfirmButton
              onClick={() => {
                setNotificationMessage('shop.pairingCode.copied', NotificationType.Success);
                if (data?.code) navigator.clipboard.writeText(data?.code);
                setOpenCreateDeviceDialog(false);
                setParentEntityToCreate(undefined);
              }}
            />
          }
        />
      )}
    </>
  );

  function getDialogContent() {
    return (
      <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <p>{intl.formatMessage({ id: 'shop.pairingCode.description' })}</p>
            <p
              id="shopDevicePairingCode"
              style={{ cursor: 'pointer', marginTop: '10px', fontWeight: 'bold' }}
              onClick={() => {
                if (data?.code) navigator.clipboard.writeText(data?.code);
              }}
            >
              {data?.code}
            </p>
          </>
        )}
      </>
    );
  }
}

export { CreateDeviceDialog };
