import { Button as MuiButton } from '@mui/material';
import { ReactNode } from 'react';
interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  variant?: 'contained' | 'outlined';
}

export function Button({ children, onClick, variant = 'contained' }: ButtonProps) {
  return (
    <MuiButton onClick={onClick} variant={variant}>
      {children}
    </MuiButton>
  );
}
