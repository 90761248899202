import { ReactNode } from 'react';

import { useGetAlertRuleTemplates } from '../../../api/hooks/useAlertRulesApi';
import { AlertRuleFormValues } from '../../../types/alert-rules/AlertRuleFormValues';
import { AlertRuleParameter } from '../../../types/alert-rules/AlertRuleParameter';
import { AlertRuleTemplate } from '../../../types/alert-rules/AlertRuleTemplate';
import { AlertRuleType } from '../../../types/alert-rules/AlertRuleType';
import { Form } from '../../library/form/Form';
import { getRequiredAndDisabled } from '../logic/AlertRuleTemplateEvaluator';
import { calculateAlertRuleThreshold } from '../logic/AlertRuleUnitCalculator';

import { AlertRuleItemTypeDropDown } from './inputs/AlertRuleItemTypeDropDown';
import { AlertRuleNameInput } from './inputs/AlertRuleNameInput';
import { AlertRuleReceiverEmailsInput } from './inputs/AlertRuleReceiverEmailsInput';
import { AlertRuleReceiverRolesInput } from './inputs/AlertRuleReceiverRolesInput';
import { AlertRuleStationDropdown } from './inputs/AlertRuleStationDropDown';
import { AlertRuleThresholdInput } from './inputs/AlertRuleThresholdInput';
import { AlertRuleTypeDropDown } from './inputs/AlertRuleTypeDropDown';

export interface AlertRuleFormProps {
  initialFormValues: AlertRuleFormValues;
  handleSubmit: (formInputValues: AlertRuleParameter) => void;
  buttons: ReactNode;
}
export function AlertRuleForm({ initialFormValues, handleSubmit, buttons }: AlertRuleFormProps) {
  const { alertRuleTemplates } = useGetAlertRuleTemplates();
  function onSubmit(formInputValues: AlertRuleFormValues) {
    handleSubmit(alertRuleParam(formInputValues, alertRuleTemplates));
  }
  return (
    <Form initialFormValues={initialFormValues} onSubmit={onSubmit} buttons={buttons}>
      <AlertRuleTypeDropDown />
      <AlertRuleNameInput />
      <AlertRuleThresholdInput />
      <AlertRuleReceiverRolesInput />
      <AlertRuleReceiverEmailsInput />
      <AlertRuleItemTypeDropDown />
      <AlertRuleStationDropdown />
    </Form>
  );
}

function alertRuleParam(
  alertRuleFormValues: AlertRuleFormValues,
  alertRuleTemplates: AlertRuleTemplate[]
): AlertRuleParameter {
  const itemTypeId = getOptionalFieldValue(
    alertRuleFormValues.itemTypeId,
    alertRuleTemplates,
    alertRuleFormValues.alertRuleType,
    'itemTypeId'
  );
  const stationId = getOptionalFieldValue(
    alertRuleFormValues.stationId,
    alertRuleTemplates,
    alertRuleFormValues.alertRuleType,
    'stationId'
  );
  const threshold = calculateAlertRuleThreshold(
    alertRuleFormValues.threshold,
    alertRuleFormValues.unit,
    getAlertRuleTemplate(alertRuleTemplates,alertRuleFormValues.alertRuleType as AlertRuleType)
  );
  const result: AlertRuleParameter = {
    alertRuleType: alertRuleFormValues.alertRuleType,
    name: alertRuleFormValues.name,
    threshold: threshold,
    itemTypeId: itemTypeId,
    stationId: stationId,
    receiverRoles: alertRuleFormValues.receiverRoles,
    receiverEmails: alertRuleFormValues.receiverEmails,
  };
  return result;
}
function getAlertRuleTemplate(alertRuleTemplates : AlertRuleTemplate[], alertRuleType: AlertRuleType) : AlertRuleTemplate | undefined{
  return alertRuleTemplates.find((at) => {return at.alertRuleType === alertRuleType});
}
function getOptionalFieldValue(
  fieldValue: string,
  alertRuleTemplates: AlertRuleTemplate[],
  alertRuleType: string,
  fieldName: string
) {
  if (fieldValue === '') return undefined;
  const { disabled } = getRequiredAndDisabled(alertRuleTemplates, alertRuleType, fieldName);
  if (disabled) return undefined;
  return fieldValue;
}
