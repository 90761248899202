import { IntlShape, useIntl } from 'react-intl';

import { useDashboardContext } from '../../context/DashboardContext';
import { RentalHistoryEntry } from '../../types/types';
import { ChartCard } from '../library/charts/ChartCard';
import { PieChart, PieChartData } from '../library/charts/PieChart';

export function BumpTestQuotaPieChartCard() {
  const intl = useIntl();
  const { rentalHistory, rentalHistoryIsLoading } = useDashboardContext();
  const closedRents = rentalHistory.filter(r => !!r.returnedOn);
  const data = toChartData(closedRents, intl);
  const title = intl.formatMessage({ id: 'dashboard.bumpTestQuotaTitle' });
  return (
    <ChartCard
      title={title}
      content={<PieChart data={data} percentages />}
      isLoading={rentalHistoryIsLoading}
      isEmpty={data.length == 0}
    />
  );
}
function toChartData(items: RentalHistoryEntry[], intl: IntlShape): PieChartData[] {
  const xDockResultCount: Record<string, number> = {};
  const filteredItems = items.filter(
    item =>
      !['Not required', 'Pending', 'Not found', 'Unknown', 'Item unkonwn to GDC'].includes(
        item.xDockResult
      )
  );
  filteredItems.forEach(item => {
    if (xDockResultCount[item.xDockResult]) {
      xDockResultCount[item.xDockResult]++;
    } else {
      xDockResultCount[item.xDockResult] = 1;
    }
  });
  const chartData: PieChartData[] = Object.keys(xDockResultCount).map((xDockResult, index) => ({
    id: index,
    value: (xDockResultCount[xDockResult] / filteredItems.length) * 100,
    label: intl.formatMessage({
      id: `bumptest-result.${xDockResult.replace(/\s+/g, '')}`,
    }),
  }));

  return chartData;
}
