import { useQuery } from 'react-query';

import { useClient } from '../../context/auth/UseClient';

export function useGetShopApiDocs() {
  const client = useClient<any>();
  const result = useQuery("SHOP_API_SPEC",() => client('/shops/api/docs'));
  return { ...result, spec: result.data?.data ?? "" };
}

export function useGetRentingApiDocs() {
  const client = useClient<any>();
  const result = useQuery("RENTING_API_SPEC",() => client('/docs/renters'));
  return { ...result, spec: result.data?.data ?? "" };
}

export function useGetReportingApiDocs() {
  const client = useClient<any>();
  const result = useQuery("REPORTING_API_SPEC",() => client('/docs/alert-rules'));
  return { ...result, spec: result.data?.data ?? "" };
}
export function useGetUserApiDocs() {
  const client = useClient<any>();
  const result = useQuery("USER_API_SPEC",() => client('/docs/system-users'));
  return { ...result, spec: result.data?.data ?? "" };
}
