import { Menu } from '@mui/material';
import { ReactNode } from 'react';

interface DropDownMenuProps {
  anchorEl: HTMLElement | null;
  id: string;
  closeMenu: () => void;
  children: ReactNode;
}
export function DropDownMenu({ anchorEl, id, closeMenu, children }: DropDownMenuProps) {
  return (
    <Menu anchorEl={anchorEl} id={id} open={!!anchorEl} onClose={closeMenu} onClick={closeMenu}>
      {children}
    </Menu>
  );
}
