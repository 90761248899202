import { IntlShape, useIntl } from 'react-intl';

import { AlertRuleType } from '../../../../types/alert-rules/AlertRuleType';
import { FormDropDown } from '../../../library/form/FormDropdown';
import { DropDownItem } from '../../../library/inputs/DropDown';

export function AlertRuleTypeDropDown() {
  const intl = useIntl();
  return (
    <FormDropDown
      id={'alertRuleTypeDropDown'}
      label={intl.formatMessage({ id: 'alert-rules.alertRuleType' })}
      name={'alertRuleType'}
      dropDownItems={alertTypeDrowDownItems(intl)}
      required={true}
    />
  );
}
function alertTypeDrowDownItems(intl: IntlShape): DropDownItem[] {
  return Object.values(AlertRuleType)
    .map(alertRuleType => ({
    value: alertRuleType.toString(),
    label: intl.formatMessage({
      id: `alert-rules.${alertRuleType.toString()}`,
    }),
  }));
}
