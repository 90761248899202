import { ReactElement } from 'react';

import { useAppContext } from '../../context/appContext';
import { useRRSystemContext } from '../../context/rrSystemContext';

import { AppNavigation } from './AppNavigation';
import { AppRoutes } from './AppRoutes';
import { SettingsModal } from './settings/SettingsModal';

function App(): ReactElement {
  const { rrSystem } = useRRSystemContext();
  const { isSettingsModalOpen } = useAppContext();

  const rrSystemId = rrSystem ? rrSystem._id : '';

  return (
    <>
      <AppNavigation />
      <div className="outer-div">
        <div className="inner-div">
          <main role="main">
            <AppRoutes key={rrSystemId} rrSystemId={rrSystemId} />
            {isSettingsModalOpen && <SettingsModal />}
          </main>
        </div>
      </div>
    </>
  );
}

export { App };
