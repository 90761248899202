import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useValidateItemService } from '../../../../api/hooks/useItemApi';
import { DefaultButtonGroup } from '../../../../components/form/DefaultButtonGroup';
import { LabeledDropdown } from '../../../../components/form/LabeledDropdown';
import { LabeledInput } from '../../../../components/form/LabeledInput';
import { LabeledTagfield } from '../../../../components/form/LabeledTagfield';
import { useCurrentUserContext } from '../../../../context/auth/CurrentUserContext';
import { OptionItem } from '../../../../types/types';
import { globalServiceTypes } from '../../../../utils/globalServices';

import { useServiceItemsErrorHandling } from './ServiceItemsErrorHandling';

export interface ServiceItemFormProps {
  onCancel: () => void;
  onSubmit: (data: ServiceItemsFormData) => void;
  serviceTypes: OptionItem[];
}

export interface ValidateItemServiceData {
  serviceTypeId: string;
  name: string;
  isGlobalService: boolean;
  itemEId: string;
  date: string;
}

export interface ServiceItemsFormData {
  serviceTypeId: string;
  name: string;
  date: string;
  itemEIds: string[];
  isGlobalService: boolean;
  userEmail: string;
}

function ServiceItemsForm({ onCancel, onSubmit, serviceTypes }: ServiceItemFormProps) {
  const intl = useIntl();
  const { userEmail } = useCurrentUserContext();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<ServiceItemsFormData>({
    mode: 'onChange',
  });
  const { mutateAsync: validateItem, isError: isValidationError, error } = useValidateItemService();

  const { getValidationErrorMessage } = useServiceItemsErrorHandling();

  const [didUserForgetServiceType, toggleDidUserForgetServiceType] = useState<boolean>(false);
  const [itemEIdsForSending, setItemEIdsForSending] = useState<string[]>([]);
  const [currentValidationError, setCurrentValidationError] = useState<string>();

  const selectedServiceTypeId = watch('serviceTypeId');
  const selectedDate = watch('date');
  const shouldShowServiceSelectionError = !selectedServiceTypeId && didUserForgetServiceType;

  function getServiceNameById(serviceTypeId: string) {
    const currentServiceType = serviceTypes.find(
      serviceType => serviceType.value === serviceTypeId
    );
    return currentServiceType?.label || '';
  }

  function getIsGlobalServiceType(serviceTypeId: string): boolean {
    return globalServiceTypes.includes(serviceTypeId);
  }

  async function validateItemEId(itemEId: string, currentTagList: string[]) {
    if (!selectedServiceTypeId) {
      toggleDidUserForgetServiceType(true);
      return;
    }
    const isValidateItemServiceGlobal = getIsGlobalServiceType(selectedServiceTypeId);
    const data: ValidateItemServiceData = {
      serviceTypeId: isValidateItemServiceGlobal ? '' : selectedServiceTypeId,
      name: isValidateItemServiceGlobal
        ? selectedServiceTypeId
        : getServiceNameById(selectedServiceTypeId),
      isGlobalService: isValidateItemServiceGlobal,
      date: selectedDate,
      itemEId,
    };
    try {
      const validation = await validateItem(data);
      if ((validation as { status: number }).status === 200) {
        const newTagList = currentTagList.concat(itemEId);
        setItemEIdsForSending(newTagList);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    if (isValidationError) {
      const newErrorMessage = getValidationErrorMessage(
        error,
        getServiceNameById(selectedServiceTypeId)
      );
      setCurrentValidationError(newErrorMessage);
    } else {
      setCurrentValidationError('');
    }
  }, [error]);

  return (
    <>
      <div className="uib-modal__content modal-form single-col">
        <form>
          <LabeledDropdown
            label={intl.formatMessage({ id: 'service-types.serviceName' })}
            id="serviceTypeId"
            options={serviceTypes}
            placeholder={intl.formatMessage({
              id: 'general.pleaseChooseEllipsis',
            })}
            disabled={selectedServiceTypeId != '' && selectedServiceTypeId != undefined}
            setValue={setValue}
            props={{ ...register('serviceTypeId', { required: true }) }}
          />
          {shouldShowServiceSelectionError && (
            <span className="form-error">
              {intl.formatMessage({
                id: 'items.updateServices.serviceTypePrompt',
              })}
            </span>
          )}
          <LabeledInput
            defaultValue={dayjs().format('YYYY-MM-DD')}
            type="date"
            id="date"
            label={intl.formatMessage({ id: 'general.date' })}
            props={{
              ...register('date', { required: true }),
            }}
          />
        </form>
        <LabeledTagfield
          label={intl.formatMessage({ id: 'items.eIDs' })}
          required={true}
          placeholder={intl.formatMessage({
            id: 'items.updateServices.tagfield.placeholder',
          })}
          validateTag={validateItemEId}
          sendUpdatedTagList={newList => setItemEIdsForSending(newList)}
        />
        {isValidationError && <span className="form-error">{currentValidationError}</span>}
      </div>

      <div className="uib-modal__footer">
        <DefaultButtonGroup
          isSubmitDisabled={isSubmitting || !isDirty || !isValid || itemEIdsForSending.length === 0}
          onSubmit={handleSubmit(data => {
            const isItemServiceGlobal = getIsGlobalServiceType(data.serviceTypeId);
            data = {
              ...data,
              serviceTypeId: isItemServiceGlobal ? '' : data.serviceTypeId,
              name: isItemServiceGlobal
                ? data.serviceTypeId
                : getServiceNameById(data.serviceTypeId),
              isGlobalService: isItemServiceGlobal,
              userEmail: userEmail,
              itemEIds: itemEIdsForSending,
            };
            onSubmit(data);
          })}
          onCancel={onCancel}
          actionButtonText={intl.formatMessage({ id: 'general.update' })}
        />
      </div>
    </>
  );
}

export { ServiceItemsForm };
