import { useState } from 'react';

import { useMenuItems } from '../../hooks/useMenuItems';

import { BurgerMenu } from './BurgerMenu';
import { Header } from './Header';

function AppNavigation() {
  const [collapsed, setCollapsed] = useState(true);
  const { menuItems } = useMenuItems();

  return (
    <>
      <BurgerMenu
        isCollapsed={collapsed}
        onCloseMenu={() => setCollapsed(true)}
        menuItems={menuItems}
      />
      <Header onOpenClick={() => setCollapsed(false)} />
    </>
  );
}

export { AppNavigation };
