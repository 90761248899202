import { ReactNode } from 'react';

import { ShopFormValues } from '../../../types/shops/ShopFormValues';
import { ShopParameter } from '../../../types/shops/ShopParameter';
import { Form } from '../../library/form/Form';

import { ShopNameInput } from './inputs/ShopNameInput';

export interface ShopFormProps {
  initialFormValues: ShopFormValues;
  handleSubmit: (formInputValues: ShopParameter) => void;
  buttons: ReactNode;
}
export function ShopForm({ initialFormValues, handleSubmit, buttons }: ShopFormProps) {
  function onSubmit(formInputValues: ShopFormValues) {
    handleSubmit(formInputValues);
  }

  return (
    <>
      <Form initialFormValues={initialFormValues} onSubmit={onSubmit} buttons={buttons}>
        <ShopNameInput />
      </Form>
    </>
  );
}
