import { AlertRuleTemplate } from '../../../types/alert-rules/AlertRuleTemplate';
import { AlertRuleUnit } from '../../../types/alert-rules/AlertRuleUnit';
import { AlertRuleUserInputUnit } from '../../../types/alert-rules/AlertRuleUserInputUnit';

export function calculateThreshold(threshold: number, unit: AlertRuleUnit): number {
  if (unit === AlertRuleUnit.Minutes) return transformMinutesToDaysOrHours(threshold);
  return threshold;
}
export function calculateUserInputUnit(
  threshold: number,
  unit: AlertRuleUnit
): AlertRuleUserInputUnit {
  switch(unit)
  {
    case AlertRuleUnit.Minutes:
      return transformUnitToDaysOrHours(threshold);
    case AlertRuleUnit.Days:
      return AlertRuleUserInputUnit.Days; 
    case AlertRuleUnit.Items:
      return AlertRuleUserInputUnit.Items; 
    default:    
      return AlertRuleUserInputUnit.Days;
  }
}
function transformUnitToDaysOrHours(threshold: number): AlertRuleUserInputUnit {
  if (isDisplayableAsDays(threshold)) return AlertRuleUserInputUnit.Days;
  if (isDisplayableAsHours(threshold)) return AlertRuleUserInputUnit.Hours;

  return AlertRuleUserInputUnit.Minutes;
}

function transformMinutesToDaysOrHours(minutes: number): number {
  if (isDisplayableAsDays(minutes)) {
    return minutes / (24 * 60);
  } else if (isDisplayableAsHours(minutes)) {
    return minutes / 60;
  } else {
    return minutes;
  }
}
function isDisplayableAsDays(minutes: number) {
  return minutes % (24 * 60) === 0;
}
function isDisplayableAsHours(minutes: number) {
  return minutes >= 60 && minutes % 60 === 0;
}

export function calculateAlertRuleThreshold(threshold: number, userInputUnit: string, alertRuleTemplate? : AlertRuleTemplate): number {
  if(alertRuleTemplate?.alertRuleUnit === AlertRuleUnit.Minutes){
    if (userInputUnit == AlertRuleUserInputUnit.Days) return daysToMinutes(threshold);
    if (userInputUnit == AlertRuleUserInputUnit.Hours) return hoursToMinutes(threshold);
  }
  return threshold;
}
function daysToMinutes(input: number) {
  return input * 24 * 60;
}
function hoursToMinutes(input: number) {
  return input * 60;
}
